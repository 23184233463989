import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <div>
        This page is not existed.{" "}
        <Link to="/" className="text-blue-400">
          Back to home
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
